import { PlusIcon } from "@heroicons/react/24/solid";
import React from "react";
import { MarketInfoQuery } from "consts/marketInfoQuery";
import MarketInformationQuery from "components/molecules/MarketInformationQuery";

const MarketInformation = () => {
  return (
    <div className="h-screen">
      <div className="flex justify-between items-center border-b h-20 px-4">
        <div className="flex items-center space-x-4">
          <h2 className="m-2 px-4 py-2 font-bold text-lg text-[#1a3939]">
            Market Information
          </h2>
        </div>
        <button className="flex justify-between items-center rounded-xl bg-[#204646] py-2 px-3 space-x-2">
          <PlusIcon className="text-[#94d04e] h-6 w-6" />
          <p className="font-semibold text-sm text-[#e2f0f0]">
            NEW MARKET QUERY
          </p>
        </button>
      </div>
      <div className="flex flex-col p-4 space-y-6 bg-[#fcfcfe]">
        {MarketInfoQuery &&
          MarketInfoQuery.map(
            ({ name, text, output, related1, related2 }, index) => {
              return (
                <MarketInformationQuery
                  queryHeader={name}
                  queryText={text}
                  output={output}
                  related1={related1}
                  related2={related2}
                  index={index}
                />
              );
            }
          )}
      </div>
    </div>
  );
};

export default MarketInformation;
