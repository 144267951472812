import React, { useRef } from "react";
import Dropdown2 from "components/atoms/Dropdown2";
import DateTimePicker from "components/atoms/DateTimePicker";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import ArchiveListTable from "components/atoms/Table2/ArchiveListTable";
const CompanyLaunch = () => {
  const fileInputRef1 = useRef(null);
  const handleButton1Click = () => {
    fileInputRef1.current.click();
  };

  const fileInputRef2 = useRef(null);
  const handleButton2Click = () => {
    fileInputRef2.current.click();
  };

  const renderFilter = () => {
    return (
      <div className="flex flex-wrap justify-between items-center">
        <div className="flex-1 flex-grow mr-5 min-w-fit">
          <p className="text-sm font-semibold text-[#214444] mb-3.5">
            Company name
          </p>
          <input className="rounded drop-shadow-input px-2 py-2 text-sm w-full text-[#210510]"></input>
        </div>
        <div className="flex-1 flex-grow mr-5 min-w-fit">
          <p className="text-sm font-semibold text-[#214444] mb-3.5">
            Grant Type
          </p>
          <Dropdown2
            label={"IIA MOP"}
            options={["IIA MOP", "EIC Accelerator"]}
            className="w-full h-9 font-semibold"
            labelClassName="justify-between text-xs text-[#210510] py-2"
          />
        </div>
        <div className="flex-1 flex-grow mr-5 min-w-fit">
          <p className="text-sm font-semibold text-[#214444] mb-3.5">
            Requested Amount
          </p>
          <input className="rounded drop-shadow-input px-2 py-2 text-sm w-full text-[#210510]"></input>
        </div>
        <div className="flex-1 flex-grow mr-5 min-w-fit">
          <p className="text-sm font-semibold text-[#214444] mb-3.5">
            Industry
          </p>
          <Dropdown2
            label={"EdTech"}
            options={[
              "Medical",
              "Software",
              "Edtech",
              "Security",
              "Manufacturing",
              "Energy",
            ]}
            className="w-full h-9 font-semibold"
            labelClassName="justify-between text-xs text-[#210510] py-2"
          />
        </div>
        <div className="flex-1 flex-grow min-w-fit">
          <p className="text-sm font-semibold text-[#214444] mb-3.5">
            Target submission Date
          </p>
          <DateTimePicker />
        </div>
      </div>
    );
  };

  const renderDescription = () => {
    return (
      <div className="font-semibold text-sm text-[#2f1b22]">
        <p>
          As we embark on the process of integrating our AI platform into your
          organization’s grant application and decision-making processes, the
          foundation for success lies in the richness of information provided.
          The depth and breadth of data shared enble our AI to offer more
          nuanced, contextual, and effective insights. Here’s what you need to
          know:
        </p>
        <ul className="list-decimal-none xl:pl-20 md:pl-10 pl-5">
          <li className="custom-bullet my-3">
            <b>Comprehensive Information Equals Better Outcomes:</b> Think of
            our AI as a highly skilled craftsman. The quality of materials (in
            this case, information) directly impacts the final product. By
            feeding it with detailed, accurate, and diverse data, the outcome is
            refined, accurate, and bespoke to your needs.
          </li>
          <li className="custom-bullet my-3">
            <b>Mandatory Data Points:</b> Certain information, such as “The
            meetings with the customers to validate the need and the solution”
            or “Customer acquisition cost”, is imperative. These are points of
            data our platform cannot intuitively know without your input.
            Providing these details ensures the AI has a comprehensive
            understanding of your organization’s landscape.
          </li>
          <li className="custom-bullet my-3">
            <b>Brevity is Fine, Clarity is Crucial:</b> We understand that some
            of the details might be concise or in short form. That’s entirely
            acceptable. Our AI is adept at elaborating concise inputs, but the
            clarity of the information is crucial. Even a succinct response can
            be a goldmine of insights when processed by our system.
          </li>
        </ul>
        <p>
          Below, you’ll find a table listing out the specifics we’d require from
          your end. This exhaustive list serves as a blueprint for our AI to
          understand, analyze, and make informed decisions tailored to your
          company’s unique ecosystem.
        </p>
      </div>
    );
  };

  const renderQuestions = () => {
    return (
      <div>
        <div className="bg-[#f4f7f0] rounded py-2 px-5  items-center flex xl:flex-row flex-col">
          <div className="flex sm:flex-row flex-col justify-between items-center xl:w-2/4 w-full">
            <span className="text-base font-semibold text-[#214444]">
              Executive Sum
            </span>
            <input className="flex-1 rounded drop-shadow-input px-2 py-2 sm:ml-10 sm:mr-3 sm:my-0 my-3 text-sm text-[#210510]"></input>
            <button
              className="flex items-center justify-center bg-[#204645] w-24 h-9 rounded-lg text-white font-semibold text-sm hover:bg-green-950 transition-colors duration-300"
              onClick={handleButton1Click}
            >
              BROWSER
              <input
                type="file"
                className="hidden"
                ref={fileInputRef1}
                onChange={(e) => {
                  console.log("File chosen:", e.target.files[0]);
                }}
              />
            </button>
          </div>
          <p className="xl:ml-10 xl:w-2/4 w-full ml-0 text-[#214444] text-sm font-semibold">
            Should include an executive summary, Vision and Mission statements
          </p>
        </div>
        <div className="flex md:flex-row flex-col mt-3">
          <div className="sm:w-2/4 w-full">
            <div className="flex  bg-[#f4f7f0] rounded py-2 pl-2 justify-between">
              <input
                className="flex-1 h-9 rounded drop-shadow-input px-2 py-2 mr-3 text-sm text-[#210510] underline font-semibold"
                placeholder="Knowledgebase File"
              ></input>
              <div className="w-24">
                <button className="flex items-center justify-center bg-[#204645] rounded-full w-9 h-9 text-white">
                  <PencilSquareIcon className="h-6 w-6 text-[#558259]" />
                </button>
              </div>
            </div>
          </div>
          <div className="sm:w-2/4 w-full sm:pl-10 sm:mt-0 mt-3">
            <p className="text-[#214444] text-base font-semibold">
              Good Questions to address:
            </p>
            <div className="bg-[#f4f7f0] py-4 mt-4 rounded relative text-sm">
              <div className="arrow"></div>
              <ul className="list-decimal-none text-[#214444] ml-10">
                <li className="custom-bullet pb-1">
                  What problem does the project aim to solve?
                </li>
                <li className="custom-bullet  pb-1">
                  Describe the product/service in detail?
                </li>
                <li className="custom-bullet  pb-1">
                  What makes the project innovative in a technological or
                  functional sense?
                </li>
                <li className="custom-bullet  pb-1">
                  Who are the major competitors and how does the company
                  differentiate from them?
                </li>
                <li className="custom-bullet  pb-1">
                  What is the strategy for entering and positioning in the
                  relevant market?
                </li>
                <li className="custom-bullet">
                  How does the company plan to generate revenue?
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAdditionFile = () => {
    return (
      <>
        <div>
          <h2 className="text-xl text-[#214444] font-semibold">
            Additional Files
          </h2>
        </div>
        <hr className="mb-10 mt-5" />
        <p className="text-sm font-semibold text-[#5f5659]">
          In our commitment to optimizing the grant application process, we’ve
          built a system where key files and data are ingested into a
          specialized vertical database. This allows our AI platform to
          intelligently engage with the information, bringing forth insights and
          elaborations when answering specific prompts. By providing the
          following recommended documents, you are empowering the AI to derive a
          more informed, comprehensive, and compelling grant application.
        </p>
        <div className="bg-[#f4f7f0] rounded py-2 px-5 mt-10 flex items-center">
          <div className="flex sm:flex-row flex-col xl:w-2/4 w-full justify-between items-center">
            <span className="text-base font-semibold text-[#214444]">
              File Type
            </span>
            <Dropdown2
              label={"RoadMap and Future Plans"}
              options={["opt 1", "opt 2"]}
              className="h-9 sm:mx-4 sm:my-0 my-3 flex-1"
              labelClassName="justify-between text-xs text-[#210510] py-2"
            />
            <input className="flex-1 h-9 rounded drop-shadow-input px-2 py-2 sm:mr-3 sm:my-0 my-3 text-sm text-[#210510]"></input>
            <button
              className="flex items-center justify-center bg-[#204645] w-24 h-9 rounded-lg text-white font-semibold text-sm hover:bg-green-950 transition-colors duration-300"
              onClick={handleButton2Click}
            >
              BROWSER
              <input
                type="file"
                className="hidden"
                ref={fileInputRef2}
                onChange={(e) => {
                  console.log("File chosen:", e.target.files[0]);
                }}
              />
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="flex justify-center items-center border-b h-20 px-4 drop-shadow-header bg-white">
        <h2 className="text-lg font-bold text-center text-[#214444]">
          Grants CoPilot File Management
        </h2>
      </div>
      <div className="m-8 p-6 drop-shadow-box bg-white rounded-3xl">
        {renderFilter()}
        <hr className="my-10" />
        {renderDescription()}
        <hr className="my-10" />
        {renderQuestions()}
        <hr className="mt-10 mb-5" />
        {renderAdditionFile()}
      </div>
      <div className="m-8">
        <h2 className="text-xl font-bold text-center text-[#214444]">
          Archive List
        </h2>
        <ArchiveListTable />
      </div>
    </>
  );
};

export default CompanyLaunch;
