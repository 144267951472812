import {
  ChatBubbleLeftRightIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/solid";
import React from "react";
import Dropdown from "./Dropdown";

const CustomerAnswer = () => {
  return (
    <div className="px-8 pb-4 flex flex-col">
      <label className="block text-sm font-semibold mb-2 self-start text-[#354b4a]">
        Guidelines
      </label>
      <textarea className="w-full h-24 border rounded-md p-2 resize-none bg-[#f4f7f0]"></textarea>

      <div className="flex items-center justify-start mt-4 space-x-2 font-semibold text-xs">
        <Dropdown
          icon={<ChatBubbleLeftRightIcon className="text-[#a2ca6c] h-3 w-3" />}
          label="ANSWER EXAMPLE"
          options={["BASE ANSWER", "FEEDBACK"]}
        />

        <button className="flex space-x-2 items-center bg-[#204646] text-white py-[7px] px-2 rounded-md hover:bg-green-950 transition-colors duration-300">
          <p>SUBMIT</p>
          <PaperAirplaneIcon className="text-[#97c981] h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

export default CustomerAnswer;
