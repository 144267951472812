import React from "react";
import cx from "classnames";

const Card = ({
  icon,
  title,
  subtitle = undefined,
  progress = undefined,
  className = "",
  iconClassName = "",
  titleClassName = "",
  subtitleClassName = "",
  progressClassname = "",
  onClick = () => {},
}) => {
  return (
    <div
      onClick={onClick}
      className={cx(
        "p-[2px] rounded-lg flex items-center space-x-2 my-[2px] mx-2 cursor-pointer hover:scale-110",
        className
      )}
    >
      {/* Icon Container */}
      <div
        className={cx(
          `rounded-lg flex items-center justify-center`,
          iconClassName
        )}
      >
        {/* Icon */}
        <div className={`w-6 h-6 flex items-center justify-center`}>
          {icon.component}
        </div>
      </div>

      <div className="flex flex-col flex-grow pr-1">
        {/* Title */}
        <div className="flex justify-between items-center">
          <div className="flex flex-col items-start">
            <p className={cx("self-start", titleClassName)}>{title}</p>
            {subtitle && <p className={subtitleClassName}>{subtitle}</p>}
          </div>
          {progress !== null && progress !== undefined && (
            <p className={progressClassname}>{progress}%</p>
          )}
        </div>
        {/* Optional progress bar */}
        {progress !== null && progress !== undefined && (
          <div className="h-1 bg-gray-200 rounded-lg mt-2 mb-2">
            <div
              className="h-full bg-[#9fcc5d] rounded-lg"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
