import cx from "classnames";
import {
  BeakerIcon,
  CheckCircleIcon,
  DocumentMagnifyingGlassIcon,
  EllipsisHorizontalCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import Card from "components/atoms/Card";
import React, { useRef, useState } from "react";
import { QuestionList } from "consts/questions";

const Sidebar = ({ currentQuestionIndex, setState }) => {
  const [showQuestions, setShowQuestions] = useState(true);
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleQuestionClick = (index) => {
    setState(() => ({
      currentQuestionIndex: index,
      currentPromptIndex: 0,
    }));
  };

  return (
    <div className="bg-white flex flex-col w-72 border border-gray-200 h-screen overflow-y-auto overflow-x-hidden">
      <div className="bg-white flex flex-col justify-center space-y-1 mt-4">
        <Card
          className="bg-[#faf5d7] text-xs"
          icon={{ component: <BeakerIcon /> }}
          iconClassName="bg-[#e7de9b] text-white h-8 w-8 mx-[6px] my-2"
          title="COMPANY"
          titleClassName="text-[#dac855] font-bold"
          subtitle="Sorlis"
          subtitleClassName="text-[#5d513e]"
        />
        <Card
          className="bg-[#fbe5d6] text-xs"
          icon={{ component: <BeakerIcon /> }}
          iconClassName="bg-[#ebc2a4] text-white h-8 w-8 mx-[6px] my-2"
          title="CONSULTANT"
          titleClassName="text-[#dfa270] font-bold"
          subtitle="Dori Stein"
          subtitleClassName="text-[#5d513e]"
        />
        <Card
          className="bg-[#d1e8f6] text-xs"
          icon={{ component: <BeakerIcon /> }}
          iconClassName="bg-[#9cc9e6] text-white h-8 w-8 mx-[6px] my-2"
          title="INDUSTRY"
          titleClassName="text-[#5fa3ca] font-bold"
          subtitle="Medical"
          subtitleClassName="text-[#5d513e]"
        />
        <Card
          className="bg-[#e9f6d9] text-xs"
          icon={{ component: <BeakerIcon /> }}
          iconClassName="bg-[#cae3a7] text-white h-8 w-8 mx-[6px] my-2"
          title="STATUS"
          titleClassName="text-[#accc80] font-bold"
          subtitle="Crafting"
          subtitleClassName="text-[#5d513e]"
        />
      </div>
      <button
        className="flex justify-center items-center bg-[#204646] rounded-lg text-slate-200 font-semibold text-sm self-center w-56 py-1 px-1 mt-4"
        onClick={handleButtonClick}
      >
        <DocumentMagnifyingGlassIcon className="h-8 w-8 text-[#98c88d]" />
        <p>Sorlis ExecutiveSummery.PDF</p>
        <input
          type="file"
          className="hidden"
          ref={fileInputRef}
          onChange={(e) => {
            console.log("File chosen:", e.target.files[0]);
          }}
        />
      </button>
      <div
        onClick={() => setShowQuestions(!showQuestions)}
        className="flex justify-between items-center my-4 cursor-pointer p-4 text-7A7A7A border-y border-gray-200"
      >
        <span className="flex justify-between items-center text-[#183131]">
          <QuestionMarkCircleIcon className="w-8 h-8 mr-2" />
          <p className="text-xl font-semibold">Questions</p>
        </span>
        {showQuestions ? "▲" : "▼"}
      </div>
      {showQuestions && (
        <>
          <Card
            className={cx({
              "bg-[#204646] shadow-lg shadow-[#d1eeb8] z-10":
                currentQuestionIndex === 0,
              "bg-white": currentQuestionIndex !== 0,
            })}
            icon={{ component: <ExclamationCircleIcon /> }}
            iconClassName="bg-[#e8acac] text-white h-8 w-8 mx-1"
            title="THE NEED"
            titleClassName="text-xs font-bold text-[#c1cbc8]"
            progress={0}
            progressClassname="text-[#b1acae] font-semibold text-xs"
            onClick={() => handleQuestionClick(0)}
          />
          <Card
            className={cx({
              "bg-[#204646] shadow-lg shadow-[#d1eeb8] z-10":
                currentQuestionIndex === 1,
              "bg-white": currentQuestionIndex !== 1,
            })}
            icon={{ component: <CheckCircleIcon /> }}
            iconClassName="bg-[#cce6a7] text-white h-8 w-8 mx-1"
            title="THE PRODUCT 1"
            titleClassName="text-xs font-bold text-[#c1cbc8]"
            progress={100}
            progressClassname="text-[#b1acae] font-semibold text-xs"
            onClick={() => handleQuestionClick(1)}
          />
          <Card
            className={cx({
              "bg-[#204646] shadow-lg shadow-[#d1eeb8] z-10":
                currentQuestionIndex === 2,
              "bg-white": currentQuestionIndex !== 2,
            })}
            icon={{ component: <BeakerIcon /> }}
            iconClassName="bg-[#f7ca88] text-white h-8 w-8 mx-1"
            title="THE PRODUCT 2"
            titleClassName="text-xs font-bold text-[#c1cbc8]"
            progress={15}
            progressClassname="text-[#73a368] font-semibold text-xs"
            onClick={() => handleQuestionClick(2)}
          />
          <Card
            className={cx({
              "bg-[#204646] shadow-lg shadow-[#d1eeb8] z-10":
                currentQuestionIndex === 3,
              "bg-white": currentQuestionIndex !== 3,
            })}
            icon={{ component: <BeakerIcon /> }}
            iconClassName="bg-[#f7ca88] text-white h-8 w-8 mx-1"
            title="THE PRODUCT 3"
            titleClassName="text-xs font-bold text-[#c1cbc8]"
            progress={15}
            progressClassname="text-[#b1acae] font-semibold text-xs"
            onClick={() => handleQuestionClick(3)}
          />
          <Card
            className="bg-white"
            icon={{ component: <EllipsisHorizontalCircleIcon /> }}
            iconClassName="bg-[#96c7e5] text-white h-8 w-8 mx-1"
            title="REGENVSTANDARDS"
            titleClassName="text-xs font-bold text-[#c1cbc8]"
            progress={15}
            progressClassname="text-[#b1acae] font-semibold text-xs"
          />
          <Card
            className="bg-white"
            icon={{ component: <CheckCircleIcon /> }}
            iconClassName="bg-[#cce6a7] text-white h-8 w-8 mx-1"
            title="CORPORATE HISTORY"
            titleClassName="text-xs font-bold text-[#c1cbc8]"
            progress={100}
            progressClassname="text-[#b1acae] font-semibold text-xs"
          />

          {QuestionList.map((question, index) => (
            <Card
              className="bg-white"
              icon={{ component: <ExclamationCircleIcon /> }}
              iconClassName="bg-[#e8acac] text-white h-8 w-8 mx-1"
              title={question}
              titleClassName="text-xs font-bold text-[#c1cbc8]"
              progress={0}
              progressClassname="text-[#b1acae] font-semibold text-xs"
            />
          ))}
        </>
      )}
    </div>
  );
};

export default Sidebar;
