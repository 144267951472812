import React from "react";
import cx from "classnames";

const TextCard = ({
  content,
  header = undefined,
  icon = undefined,
  className = "",
  contentClassName = "",
  headerClassName = "",
  children = undefined,
}) => {
  return (
    <div className={cx("bg-white rounded-md flex flex-col", className)}>
      {(header || icon) && (
        <div
          className={cx(
            "h-12 flex items-center w-full border-b px-6 py-2 space-x-2"
          )}
        >
          {icon && (
            <div
              className={cx(
                "flex items-center justify-center h-6 w-6 mr-2 rounded-full"
              )}
            >
              {icon}
            </div>
          )}
          {header && (
            <h2 className={cx("font-semibold text-md my-2", headerClassName)}>
              {header}
            </h2>
          )}
        </div>
      )}
      {content && (
        <div className={cx("px-6 py-4 overflow-y-auto", contentClassName)}>
          {content.split("\n").map((line, idx) => (
            <p key={idx}>{line}</p>
          ))}
        </div>
      )}
      {children}
    </div>
  );
};

export default TextCard;
