import React, { useState } from "react";
import cx from "classnames";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

const Dropdown = ({
  label = "",
  options,
  icon = undefined,
  className = "",
  labelClassName = "",
  placeholder = "Select an option", // Default placeholder if none provided
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(label);

  const handleOptionClick = (option) => {
    setSelectedLabel(option);
    setIsOpen(false);
  };

  // Display placeholder if selectedLabel is an empty string
  const displayLabel = selectedLabel === "" ? placeholder : selectedLabel;

  return (
    <div className={cx("relative inline-block text-left", className)}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={cx(
          "inline-flex justify-center items-center space-x-2 w-full px-2 py-1 text-sm font-medium bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-inherit",
          labelClassName
        )}
      >
        {icon}
        <p>{displayLabel}</p>
        {isOpen ? (
          <ChevronUpIcon className="h-4 w-4" />
        ) : (
          <ChevronDownIcon className="h-4 w-4" />
        )}
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 min-w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 text-inherit z-10">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options &&
              options.map((option, index) => (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  key={index}
                  href="#"
                  className="block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOptionClick(option);
                  }}
                >
                  {option}
                </a>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
