import React, { useState } from "react";
import cx from "classnames";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

const Dropdown2 = ({
  label,
  options,
  icon = undefined,
  className = "",
  labelClassName = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={cx("relative inline-block text-left", className)}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={cx(
          "inline-flex justify-center items-center space-x-2 w-full px-2 py-1 text-sm rounded bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-inherit h-full drop-shadow-input",
          labelClassName
        )}
      >
        {icon}
        <p>{label}</p>
        {isOpen ? (
          <ChevronUpIcon className="h-4 w-4" />
        ) : (
          <ChevronDownIcon className="h-4 w-4" />
        )}
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 text-inherit w-full">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options.map((option, index) => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                key={index}
                href="#"
                className="block px-4 py-2 text-sm hover:bg-gray-100"
                role="menuitem"
              >
                {option}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown2;
