import {
  QuestionMarkCircleIcon,
  DocumentDuplicateIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import TextCard from "components/atoms/TextCard";
import React, { useState } from "react";
import Dropdown from "components/atoms/Dropdown";
import cx from "classnames";
import { ReactComponent as SpinnerIcon } from "assets/spinner.svg";

const MarketInformationQuery = ({
  queryHeader,
  queryText,
  output,
  related1 = "",
  related2 = "",
  className = "",
  index,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const handlePlayClick = () => {
    setShowSpinner(true);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
    setTimeout(() => setShowPopup(false), 2000);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(queryText)
      .then(() => {
        togglePopup();
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  return (
    <div className={cx("flex justify-start h-44 space-x-2", className)}>
      <TextCard
        className="w-1/3 border rounded-xl"
        header={queryHeader}
        icon={<QuestionMarkCircleIcon className="h-16 w-16 text-[#595959]" />}
        content={queryText}
        contentClassName="text-start"
      />
      <div className="relative flex justify-center items-center">
        <DocumentDuplicateIcon
          className="h-8 w-8 self-center bg-[#214545] text-[#5a8559] rounded-full p-1 cursor-pointer"
          onClick={handleCopyToClipboard}
        />
        {showPopup && (
          <div className="absolute min-w-max top-2/3 bg-white p-2 rounded-lg shadow-md">
            Copied to clipboard!
          </div>
        )}
      </div>

      <div className="w-1/3 flex flex-col border rounded-xl bg-white">
        <p className="self-start text-sm font-semibold text-[#5b6f6f] m-3">
          {`Query ${index + 1}: Feedback and selections`}
        </p>
        <div className="overflow-y-auto overflow-x-hidden h-full text-start bg-[#f4f7f0] p-1 rounded break-words px-4">
          {output}
        </div>
      </div>
      <div className="flex flex-col w-1/4 border rounded-xl p-4 space-y-2 bg-white">
        <p className="self-start text-sm font-semibold text-[#5b6f6f] mb-1">
          Related Question
        </p>
        <Dropdown
          label={related1}
          placeholder=""
          options={["Needs", "Competitors", "Market"]}
          className="px-2"
          labelClassName="justify-between font-semibold text-xs text-[#685f62] py-2 z-10"
        />
        <Dropdown
          label={related2}
          placeholder=""
          options={["Needs", "Competitors", "Market"]}
          className="px-2"
          labelClassName="justify-between font-semibold text-xs text-[#685f62] py-2 z-10"
        />
      </div>
      {showSpinner ? (
        <SpinnerIcon className="h-8 w-8 self-center bg-[#214545] text-[#5a8559] rounded-full p-1 cursor-pointer" />
      ) : (
        <PlayIcon
          className="h-8 w-8 self-center bg-[#214545] text-[#5a8559] rounded-full p-1 cursor-pointer"
          onClick={handlePlayClick}
        />
      )}
    </div>
  );
};

export default MarketInformationQuery;
