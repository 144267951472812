import React, { useRef, useState } from "react";
import {
  GlobeAltIcon,
  EllipsisHorizontalIcon,
  ArrowDownOnSquareStackIcon,
  ArrowUpTrayIcon,
  PencilSquareIcon,
  ChatBubbleLeftRightIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/solid";
import TextCard from "components/atoms/TextCard";
import Dropdown from "components/atoms/Dropdown";

const Feedback = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [perplexity, setPerplexity] = useState("1500");
  const inputRef = useRef(null);
  const inputWidth = `${8 * perplexity.length}px`;

  return (
    <TextCard
      className="basis-1/2"
      header={
        <h2 className="flex justify-between items-center w-full font-semibold text-md my-2 relative">
          <Dropdown
            icon={<GlobeAltIcon className="h-6 w-6" />}
            label="Hebrew"
            options={["English", "Hebrew"]}
          />
          <button
            onClick={() => setDropdownOpen(!isDropdownOpen)}
            className="focus:outline-none"
          >
            <EllipsisHorizontalIcon className="h-8 w-8" />
          </button>
          {isDropdownOpen && (
            <div className="absolute top-full right-0 bg-white border rounded shadow w-50">
              {/* Add top-full and w-40 for width adjustment */}
              <h3 className="flex justify-start text-semibold text-xs text-[#687c7d] border-b border-[#f1f1f1] py-2 px-4">
                PERPLEXITY
              </h3>
              <div className="flex flex-col items-start justify-center py-2 px-4 space-y-1 text-sm">
                <div className="flex hover:text-[#bdcfb4] cursor-pointer justify-start w-full">
                  <span>Download Prompts</span>
                  <ArrowDownOnSquareStackIcon className="h-4 w-4 ml-2" />
                </div>
                <div className="hover:text-[#bdcfb4] cursor-pointer">
                  Upload Perplexity Output
                </div>
              </div>
            </div>
          )}
        </h2>
      }
      headerClassName="flex justify-start text-[#18302f] w-full"
      content=""
    >
      <div className="flex flex-col px-8 mt-4">
        <div className="flex items-center">
          <p className="font-semibold text-sm text-[#8c9a9a]">
            Target number of words
          </p>
          <span className="flex items-center justify-center border rounded-xl p-2 mx-2 text-xs min-w-max font-bold">
            <span>A</span> <span className="text-[#8eb790]">a</span>
            <input
              type="number"
              value={perplexity}
              onChange={(e) => setPerplexity(e.target.value)}
              className="w-8 mx-1 text-[#647070]"
              ref={inputRef}
              style={{ width: inputWidth }}
            />
          </span>
        </div>
        <div className="flex justify-between items-center mt-2 bg-[#f2f5ee] rounded">
          <span className="basis-5/6 m-1 bg-white flex justify-start items-center px-2 py-1 underline rounded text-xs font-semibold text-[#657777]">
            Company File
          </span>
          <div className="flex items-center justify-center space-x-2 mr-2">
            <PencilSquareIcon className="h-4 w-4 text-[#75a760]" />
            <label htmlFor="fileInput">
              <ArrowUpTrayIcon className="h-4 w-4 text-[#75a760] cursor-pointer" />
            </label>
            <input
              type="file"
              id="fileInput"
              className="hidden"
              onChange={(e) => {
                console.log("File chosen:", e.target.files[0]);
              }}
            />
          </div>
        </div>
      </div>
      <div className="px-8 py-4 flex flex-col">
        <label className="block text-sm font-semibold mb-2 self-start text-[#354b4a]">
          Guidelines
        </label>
        <textarea className="w-full h-52 border rounded-md p-2 resize-none bg-[#f4f7f0]"></textarea>

        <div className="flex items-center justify-start mt-4 space-x-2 font-semibold text-xs">
          <Dropdown
            icon={
              <ChatBubbleLeftRightIcon className="text-[#a2ca6c] h-3 w-3" />
            }
            label="ANSWER EXAMPLE"
            options={["BASE ANSWER", "FEEDBACK"]}
          />

          <button className="flex space-x-2 items-center bg-[#204646] text-white py-[7px] px-2 rounded-md hover:bg-green-950 transition-colors duration-300">
            <p>SUBMIT</p>
            <PaperAirplaneIcon className="text-[#97c981] h-4 w-4" />
          </button>
        </div>
      </div>
    </TextCard>
  );
};

export default Feedback;
