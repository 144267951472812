import {
  ChatBubbleLeftEllipsisIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/solid";
import Header from "components/atoms/Header";
import TextCard from "components/atoms/TextCard";
import Sidebar from "components/molecules/Sidebar";
import React, { useState } from "react";
import Feedback from "components/molecules/Feedback";
import Preliminary from "components/molecules/Preliminary";
import { Questions } from "consts/questions";

const Page1 = () => {
  const [state, setState] = useState({
    currentQuestionIndex: 0,
    currentPromptIndex: 0,
  });

  const currentQuestion = Questions[state.currentQuestionIndex];
  const currentPrompt = currentQuestion.prompts[state.currentPromptIndex];

  const handleNextQuestion = () => {
    if (state.currentQuestionIndex < Questions.length - 1) {
      setState(() => ({
        currentQuestionIndex: state.currentQuestionIndex + 1,
        currentPromptIndex: 0,
      }));
    }
  };

  const handlePrevQuestion = () => {
    if (state.currentQuestionIndex > 0) {
      setState(() => ({
        currentQuestionIndex: state.currentQuestionIndex - 1,
        currentPromptIndex: 0,
      }));
    }
  };

  const handleFirstQuestion = () => {
    setState(() => ({
      currentQuestionIndex: 0,
      currentPromptIndex: 0,
    }));
  };

  const handleNextPrompt = () => {
    if (state.currentPromptIndex < currentQuestion.prompts.length - 1) {
      setState(() => ({
        currentQuestionIndex: state.currentQuestionIndex,
        currentPromptIndex: state.currentPromptIndex + 1,
      }));
    }
  };

  const handleLastPrompt = () => {
    setState(() => ({
      currentQuestionIndex: state.currentQuestionIndex,
      currentPromptIndex:
        Questions[state.currentQuestionIndex].prompts.length - 1,
    }));
  };

  const handlePrevPrompt = () => {
    if (state.currentPromptIndex > 0) {
      setState(() => ({
        currentQuestionIndex: state.currentQuestionIndex,
        currentPromptIndex: state.currentPromptIndex - 1,
      }));
    }
  };

  const handleFirstPrompt = () => {
    setState(() => ({
      currentQuestionIndex: state.currentQuestionIndex,
      currentPromptIndex: 0,
    }));
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex flex-grow">
        <Sidebar
          currentQuestionIndex={state.currentQuestionIndex}
          setState={setState}
        />
        <div className="w-full bg-gray-100">
          <Header
            title={`${currentQuestion.name}`}
            className="bg-white pl-2"
            showEllipsis={true}
            onLeftButtonClick={handleFirstQuestion}
            onNextButtonClick={handleNextQuestion}
            onPrevButtonClick={handlePrevQuestion}
          />
          <div className="p-4">
            <TextCard
              className="text-sm"
              content={currentQuestion.value}
              contentClassName="text-right"
            />
            <div className="flex flex-col">
              <Header
                className="bg-transparent shadow-none"
                title={`${currentPrompt.status}`}
                onRightButtonClick={handleLastPrompt}
                onLeftButtonClick={handleFirstPrompt}
                onNextButtonClick={handleNextPrompt}
                onPrevButtonClick={handlePrevPrompt}
              />
              <div className="flex space-x-4">
                {(currentPrompt &&
                  state.currentQuestionIndex === 0 &&
                  state.currentPromptIndex === 0) ||
                (state.currentQuestionIndex === 2 &&
                  state.currentPromptIndex === 0) ? (
                  <>
                    <TextCard
                      icon={<DocumentTextIcon className="h-8 w-8" />}
                      className="basis-1/2 text-[#183131]"
                      header="GrowthGarner Preliminary Answer"
                      content={currentPrompt.output}
                      contentClassName="h-80 overflow-y-auto text-xs font-semibold text-[#564d50] text-start"
                    >
                      <button className="flex justify-between items-center border rounded-3xl m-6 p-2 self-end font-semibold">
                        <span className="flex mr-1 items-center ">
                          <span>A</span>
                          <span className="text-[#8eb790]">a</span>
                        </span>
                        <p className="text-[#637171] text-sm">
                          {`${currentPrompt.output_words}
                           words 
                            ${currentPrompt.output_characters}
                           characters`}
                        </p>
                      </button>
                    </TextCard>
                    <Preliminary />
                  </>
                ) : (
                  <>
                    <TextCard
                      icon={<DocumentTextIcon className="h-8 w-8" />}
                      className="basis-1/2 text-[#183131]"
                      header="GrowthGarner Preliminary Answer"
                      content={currentPrompt.output}
                      contentClassName="h-80 overflow-y-auto text-xs font-semibold text-[#564d50] text-start"
                    >
                      <button className="flex justify-between items-center border rounded-3xl m-6 p-2 self-end font-semibold">
                        <span className="flex mr-1 items-center ">
                          <span>A</span>
                          <span className="text-[#8eb790]">a</span>
                        </span>
                        <p className="text-[#637171] text-sm">
                          {`${currentPrompt.output_words}
                           words 
                            ${currentPrompt.output_characters}
                           characters`}
                        </p>
                      </button>
                    </TextCard>
                    <Feedback />
                  </>
                )}
              </div>
            </div>
            <TextCard
              icon={<ChatBubbleLeftEllipsisIcon className="h-8 w-8" />}
              className="mt-4"
              header="Tips and Guidelines"
              headerClassName="text-[#113231]"
              content={currentQuestion && currentQuestion.tips}
              contentClassName="h-32 overflow-y-auto text-xs font-semibold text-[#564d50] text-start"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page1;
