import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "assets/logo.jpeg";
import { ChevronDownIcon, UserIcon } from "@heroicons/react/24/solid";

const Header = ({ showProfile = true }) => {
  const [openProfile, setOpenProfile] = useState(false);

  return (
    <div className="bg-[#210511] p-4 flex justify-between relative">
      <Link to="/">
        <img src={logo} alt="logo" className="h-16 w-64" />
      </Link>
      {showProfile && (
        <div
          className="flex items-center justify-end space-x-2 cursor-pointer"
          onClick={() => setOpenProfile(!openProfile)}
        >
          <div className="text-white bg-[#477350] p-2 rounded-lg">
            <UserIcon className="h-6 w-6 " />
          </div>
          <p className="text-[#c4dadc] font-semibold">Dori Stein</p>
          <ChevronDownIcon className="h-4 w-4 text-[#80ab81]" />
        </div>
      )}
      {openProfile && (
        <div className="absolute top-16 right-4 mt-2 w-48 py-2 bg-white rounded-lg shadow-2xl z-50 font-semibold text-[#1d3c3c]">
          <Link
            to="/grants"
            className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
          >
            Grant List
          </Link>
          <Link
            to="/marketInfo"
            className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
          >
            Market Information
          </Link>
          <Link
            to="/companylaunch"
            className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
          >
            Company Launch
          </Link>
        </div>
      )}
    </div>
  );
};

export default Header;
