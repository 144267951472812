import React, { useRef, useState } from "react";
import {
  ArrowUpTrayIcon,
  EllipsisHorizontalIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

const FileItem = ({ title }) => {
  const [perplexity, setPerplexity] = useState("1500");
  const inputRef = useRef(null);
  const inputWidth = `${8 * perplexity.length}px`;

  return (
    <>
      <span className="col-span-3 m-1 bg-white flex justify-start items-center px-2 py-1 underline rounded text-xs font-semibold text-[#657777]">
        {title}
      </span>
      <div className="col-span-1 flex items-center justify-center space-x-2">
        <PencilSquareIcon className="h-4 w-4 text-[#75a760]" />

        <label htmlFor="fileInput">
          <ArrowUpTrayIcon className="h-4 w-4 text-[#75a760] cursor-pointer" />
        </label>
        <input
          type="file"
          id="fileInput"
          className="hidden"
          onChange={(e) => {
            console.log("File chosen:", e.target.files[0]);
          }}
        />
      </div>
      <div
        className="col-span-1 flex items-center justify-center whitespace-nowrap text-center bg-white rounded-xl my-1 text-xs min-w-max"
        onClick={() => inputRef.current.focus()}
      >
        <span>A</span> <span className="text-[#8eb790]">a</span>
        <input
          type="number"
          value={perplexity}
          onChange={(e) => setPerplexity(e.target.value)}
          className="w-8 mx-1 text-[#647070]"
          ref={inputRef}
          style={{ width: inputWidth }} // Set the width dynamically
        />
      </div>
      <div className="col-span-1 flex items-center justify-center">
        <Link to="/marketInfo">
          <EllipsisHorizontalIcon className="h-4 w-4 text-[#75a760]" />
        </Link>
      </div>
    </>
  );
};

export default FileItem;
