import { GlobeAltIcon } from "@heroicons/react/24/solid";
import CustomerAnswer from "components/atoms/CustomerAnswer";
import Dropdown from "components/atoms/Dropdown";
import TextCard from "components/atoms/TextCard";
import React from "react";
import FileGrid from "./FileGrid";

const Preliminary = () => {
  return (
    <TextCard
      className="basis-1/2"
      header={
        <div className="flex items-center">
          <p className="px-4">Language</p>
          <Dropdown
            icon={<GlobeAltIcon className="h-6 w-6" />}
            label="Hebrew"
            options={["English", "Hebrew"]}
          />
        </div>
      }
      headerClassName="flex justify-end text-[#18302f] w-full"
      content=""
    >
      <FileGrid />
      <CustomerAnswer />
    </TextCard>
  );
};

export default Preliminary;
