import React, { useRef } from "react";
import FileItem from "components/atoms/FileItem";
import { PlusCircleIcon } from "@heroicons/react/24/solid";

const FileGrid = () => {
  const files = [
    { title: "Company file 1" },
    { title: "Company file 2" },
    { title: "Company file 3" },
  ];

  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="px-8 mt-4 flex flex-col">
      <div className="grid auto-rows-auto grid-cols-6 gap-1 mb-2 font-semibold text-[10px]">
        <span className="col-span-4"></span>
        <span className="col-span-1 text-center">TARGET # WORDS</span>
        <span className="col-span-1 text-center">PERPLEXITY</span>
      </div>
      {files.map((file, index) => (
        <div
          key={index}
          className="grid auto-rows-auto grid-cols-6 mb-2 bg-[#f4f7f0] rounded py-[1px]"
        >
          <FileItem title={file.title} />
        </div>
      ))}
      <div className="flex justify-end items-center w-full cursor-pointer">
        <PlusCircleIcon
          className="text-green-700 h-8 w-8"
          onClick={handleIconClick}
        />
        <input type="file" ref={fileInputRef} className="hidden" />
      </div>
    </div>
  );
};

export default FileGrid;
