import { PlusIcon, UserIcon } from "@heroicons/react/24/solid";
import GrantListTable from "components/atoms/Table/GrantListTable";
import React from "react";

const GrantList = () => {
  return (
    <div className="bg-[#fcfcfe] h-full">
      <div className="flex justify-between items-center border-b h-20 px-4">
        <div className="flex items-center space-x-4">
          <h2 className="border-r m-2 px-4 py-2 font-bold text-lg text-[#1a3939]">
            Grants List
          </h2>
          <div className="text-white bg-[#f0d7c3] p-2 rounded-lg">
            <UserIcon className="h-6 w-6" />
          </div>
          <div className="flex flex-col items-start">
            <p className="font-bold text-xs text-[#ddba9a]">CONSULTANT</p>
            <p className="text-sm">Dori Stein</p>
          </div>
        </div>
        <button className="flex justify-between items-center rounded-xl bg-[#204646] py-2 px-3 space-x-2">
          <PlusIcon className="text-[#94d04e] ch-6 w-6" />
          <p className="font-semibold text-sm text-[#e2f0f0]">NEW GRANT</p>
        </button>
      </div>
      <div>
        <GrantListTable />
      </div>
    </div>
  );
};

export default GrantList;
