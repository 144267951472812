import React from "react";
import cx from "classnames";
import {
  CheckCircleIcon,
  ClockIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

const ActionButtons = ({ editable }) => (
  <div className="flex justify-center items-center space-x-2">
    {editable ? (
      <Link to="/">
        <button className="p-2 flex justify-center items-center border rounded-xl">
          <PencilSquareIcon className="text-[#ccd6c8] h-4 w-4 mr-1" />
          <span className="text-[#7d8585]">Edit</span>
        </button>
      </Link>
    ) : (
      <button className="p-2 flex justify-center items-center border rounded-xl">
        <PencilSquareIcon className="text-[#ccd6c8] h-4 w-4 mr-1" />
        <span className="text-[#7d8585]">Edit</span>
      </button>
    )}

    <button className=" p-2 flex justify-center items-center border rounded-xl">
      <TrashIcon className="text-[#ccd6c8] h-4 w-4 mr-1" />
      <span className="text-[#7d8585]">Delete</span>
    </button>
  </div>
);

const Row = ({ data, className = "" }) => {
  const { editable, ...rest } = data;
  return (
    <tr className={cx(className)}>
      {Object.values(rest).map((value, idx) => (
        <td
          key={idx}
          className={cx("py-2 px-4 text-center align-middle", {
            "flex justify-center items-center h-12":
              value === "In process" || value === "Finalized",
          })}
        >
          {value === "In process" && (
            <ClockIcon className="h-4 w-4 text-[#dab77b]" />
          )}
          {value === "Finalized" && (
            <CheckCircleIcon className="h-4 w-4 text-[#a7d06d]" />
          )}
          {value}
        </td>
      ))}
      <td className="py-2 px-4">
        <ActionButtons editable={editable} />
      </td>
    </tr>
  );
};

export default Row;
