import React from "react";
import cx from "classnames";
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/solid";

const Header = ({
  title,
  className = "",
  showEllipsis = false,
  onRightButtonClick = () => {},
  onLeftButtonClick = () => {},
  onNextButtonClick = () => {},
  onPrevButtonClick = () => {},
}) => {
  return (
    <div
      className={cx("flex justify-between w-full h-16 shadow-md", className)}
    >
      <div
        className={cx("flex items-center justify-center grow basis-full", {
          "space-x-6": showEllipsis,
        })}
      >
        <button
          className="px-2 py-1 mx-[1px] rounded-xl bg-[#e4f4cd] hover:bg-gray-300"
          onClick={onLeftButtonClick}
        >
          <ChevronDoubleLeftIcon className="h-5 w-5 text-[#1d1109]" />
        </button>
        <button
          className="px-2 py-1 mx-[1px] rounded-xl bg-[#f2f9e7] hover:bg-gray-300"
          onClick={onPrevButtonClick}
        >
          <ChevronLeftIcon className="h-3 w-3 text-[#4b5f54]" />
        </button>
        <div className="w-48 flex justify-center items-center px-4 text-lg font-bold text-[#1d3c3c]">
          {title}
        </div>
        <button
          className="px-2 py-1 mx-[1px] rounded-xl bg-[#f2f9e7] hover:bg-gray-300"
          onClick={onNextButtonClick}
        >
          <ChevronRightIcon className="h-3 w-3 text-[#4b5f54]" />
        </button>
        <button
          className="px-2 py-1 mx-[1px] rounded-xl bg-[#e4f4cd] hover:bg-gray-300"
          onClick={onRightButtonClick}
        >
          <ChevronDoubleRightIcon className="h-5 w-5 text-[#1d1109]" />
        </button>
      </div>
      {showEllipsis && (
        <button className="">
          <EllipsisHorizontalIcon className="h-8 w-8" />
        </button>
      )}
    </div>
  );
};

export default Header;
