import React, { useRef } from "react";
import cx from "classnames";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";

const ActionButtons = () => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="flex justify-center items-center space-x-2 cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.557"
        height="13"
        viewBox="1051 350 14.557 13"
        onClick={handleButtonClick}
      >
        <path
          d="M1054.789 354.013a.526.526 0 0 1 0-.741l3.119-3.12a.526.526 0 0 1 .74 0l3.12 3.12a.526.526 0 0 1 0 .74c-.207.2-.534.2-.74 0l-2.23-2.235v5.504a.52.52 0 0 1-1.04 0v-5.504l-2.229 2.236a.526.526 0 0 1-.74 0m10.768 3.788v4.16a1.04 1.04 0 0 1-1.04 1.039h-12.477a1.04 1.04 0 0 1-1.04-1.04v-4.159c0-.574.465-1.04 1.04-1.04h4.419a.26.26 0 0 1 .26.26v.228c0 .87.715 1.611 1.592 1.592a1.56 1.56 0 0 0 1.527-1.56v-.26a.26.26 0 0 1 .26-.26h4.419c.574 0 1.04.466 1.04 1.04m-2.6 2.08a.78.78 0 1 0-1.56 0 .78.78 0 0 0 1.56 0"
          fill="#74a85e"
          fill-rule="evenodd"
          data-name="icon-upload"
        />
      </svg>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={(e) => {
          console.log("File chosen:", e.target.files[0]);
        }}
      />
    </div>
  );
};

const Row = ({ data, className = "" }) => (
  <tr className={cx(className)}>
    {Object.values(data).map((value, idx) => (
      <td
        key={idx}
        className={cx("py-4 px-8 text-start align-middle", {
          "flex justify-start items-center h-12": idx === 2,
        })}
      >
        {idx === 2 && <CalendarDaysIcon className="h-4 w-4 mr-1" />}
        {value}
      </td>
    ))}
    <td className="py-2 px-4">
      <ActionButtons />
    </td>
  </tr>
);

export default Row;
