import React from "react";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";

const DateTimePicker = ({ className = "" }) => {
  const renderInput = (props, openCalendar, closeCalendar) => {
    return (
      <div className="w-full relative">
        <input
          {...props}
          className="rounded drop-shadow-input px-2 py-2 pr-8 text-sm w-full text-[#210510]"
        />
        <button className="absolute h-full p-1 right-0" onClick={openCalendar}>
          <CalendarDaysIcon className="h-6 w-6 text-[#214444]" />
        </button>
      </div>
    );
  };
  return <Datetime renderInput={renderInput} />;
};

export default DateTimePicker;
