import React, { useState } from "react";
import Row from "./TableRow";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import cx from "classnames";

const Table = () => {
  const [data, setData] = useState([
    {
      fileType: "Product Architecture Overview",
      fileName: "PA Arch.PDF",
      uploadDate: "17/03/2023",
    },
    {
      fileType: "MVP Description",
      fileName: "MVP Des.PDF",
      uploadDate: "09/09/2023",
    },
    {
      fileType: "Specification Files",
      fileName: "Spec 1.txt",
      uploadDate: "09/09/2023",
    },
    {
      fileType: "Research Reports",
      fileName: "Mackinzy Research 02.pdf",
      uploadDate: "09/09/2023",
    },
  ]);
  const [sortDirection, setSortDirection] = useState({
    companyName: "",
    grantType: "",
  });

  const handleSort = (column) => {
    let sortedData;
    if (!sortDirection[column] || sortDirection[column] === "desc") {
      sortedData = [...data].sort((a, b) => (a[column] > b[column] ? 1 : -1));
      setSortDirection({ ...sortDirection, [column]: "asc" });
    } else {
      sortedData = [...data].sort((a, b) => (a[column] > b[column] ? -1 : 1));
      setSortDirection({ ...sortDirection, [column]: "desc" });
    }
    setData(sortedData);
  };
  return (
    <div className="mt-8 overflow-x-auto shadow-md rounded-lg pb-8">
      <table className="min-w-full bg-white text-xs font-semibold rounded-3xl">
        <thead>
          <tr className="bg-[#c1e198] text-[#3d5f47]">
            <th
              className="py-2 px-8 text-sm font-bold text-start flex justify-start items-center"
              onClick={() => handleSort("fileType")}
            >
              <span>File Type</span>
              <ChevronUpDownIcon className="h-4 w-4" />
            </th>
            <th className="py-2 px-8 text-sm font-bold text-start">
              File Name
            </th>
            <th className="py-2 px-8 text-sm font-bold text-start">
              Upload Date
            </th>
            <th className="py-2 px-8 text-sm font-bold text-center">Edit</th>
          </tr>
        </thead>
        <tbody className="text-[#7d7276]">
          {data.map((row, idx) => (
            <Row
              key={idx}
              data={row}
              className={cx("text-start", {
                "bg-white": idx % 2 === 0,
                "bg-[#f9faf5]": idx % 2 !== 0,
              })}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
