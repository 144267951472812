import React, { useState } from "react";
import Row from "./TableRow";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import cx from "classnames";

const Table = () => {
  const [data, setData] = useState([
    {
      companyName: "Brenmiller Energy",
      full: "100%",
      industry: "Energy Storage",
      grantType: "EIC",
      submissionDate: "16/10/2023",
      status: "Finalized",
    },
    {
      companyName: "Evovleme",
      full: "95%",
      industry: "Consulting",
      grantType: "IIA_MOP",
      submissionDate: "30/09/2023",
      status: "In process",
    },
    {
      companyName: "Sorlis",
      full: "17%",
      industry: "Medical",
      grantType: "IIA_MOP",
      submissionDate: "15/12/2023",
      status: "In process",
      editable: true,
    },
    {
      companyName: "VR School",
      full: "75%",
      industry: "EdTech",
      grantType: "IIA_Tnufa",
      submissionDate: "16/10/2023",
      status: "In process",
    },
  ]);
  const [sortDirection, setSortDirection] = useState({
    companyName: "",
    grantType: "",
  });

  const handleSort = (column) => {
    let sortedData;
    if (!sortDirection[column] || sortDirection[column] === "desc") {
      sortedData = [...data].sort((a, b) => (a[column] > b[column] ? 1 : -1));
      setSortDirection({ ...sortDirection, [column]: "asc" });
    } else {
      sortedData = [...data].sort((a, b) => (a[column] > b[column] ? -1 : 1));
      setSortDirection({ ...sortDirection, [column]: "desc" });
    }
    setData(sortedData);
  };
  return (
    <div className="m-8 overflow-x-auto shadow-md rounded-lg pb-4">
      <table className="min-w-full bg-white text-xs font-semibold rounded-3xl">
        <thead>
          <tr className="bg-[#c1e198] text-[#3d5f47]">
            <th
              className="py-2 px-4 text-center flex justify-center items-center"
              onClick={() => handleSort("companyName")}
            >
              <span>COMPANY NAME</span>
              <ChevronUpDownIcon className="h-4 w-4" />
            </th>
            <th className="py-2 px-4 text-center">% FULL</th>
            <th className="py-2 px-4 text-center">INDUSTRY</th>
            <th
              className="py-2 px-4 text-center flex justify-center items-center"
              onClick={() => handleSort("grantType")}
            >
              <span>GRANT TYPE</span>
              <ChevronUpDownIcon className="h-4 w-4" />
            </th>
            <th className="py-2 px-4 text-center">TARGET SUBMISSION DATE</th>
            <th className="py-2 px-4 text-center">STATUS</th>
            <th className="py-2 px-4 text-center">ACTION</th>
          </tr>
        </thead>
        <tbody className="text-[#7d7276]">
          {data.map((row, idx) => (
            <Row
              key={idx}
              data={row}
              className={cx("text-center", {
                "bg-white": idx % 2 === 0,
                "bg-[#f9faf5]": idx % 2 !== 0,
              })}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
