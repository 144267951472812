import "./App.css";
import Page1 from "components/organisms/Page1";
import GrantList from "components/organisms/GrantList";
import MarketInformation from "components/organisms/MarketInformation";
import CompanyLaunch from "components/organisms/CompanyLaunch";
import {
  createBrowserRouter,
  Outlet,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import Logo from "components/atoms/Logo";
import React from "react";

const router = createBrowserRouter([{ path: "*", Component: Root }]);

export default function App() {
  return <RouterProvider router={router} />;
}

function Root() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<Page1 />} />
        <Route path="/grants" element={<GrantList />} />
        <Route path="/marketinfo" element={<MarketInformation />} />
        <Route path="/companylaunch" element={<CompanyLaunch />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <>
      <div className="App w-screen h-screen overflow-x-hidden">
        {/* 2️⃣ Render the app routes via the Layout Outlet */}
        <Logo showProfile={true} />
        <Outlet />
      </div>
    </>
  );
}
